import React from "react";
import QRCODE from '../../../images/qrcode.svg';
import Verified from "../../../icons/verifieduser.svg";
import Stethoscope from "../../../icons/stethoscope2.svg";
import { useSelector } from "react-redux";
import './profile.css';

const ProfileVerified = () => {
    const state = useSelector(state => state.userProfile.userProfile);
    console.log(state, "profilesss");

    return (
        <div>
            <div>
                <div className="d-flex jc__center">
                    <div className="flx-center">
                        <img src={Verified} style={{ paddingRight: "5px" }} width="18px" height="18px" alt="Verified" />
                        <span className="fntLg fntSemiBold">Registered Medical Doctor</span>
                    </div>
                </div>
                <div className="pt-05 ph1">
                    <div className="flx-center pt-05 " style={{ gap: "10px" }}>
                        <div style={{ width: "70%" }}>
                            <div>
                                {
                                    state.practioner?.identifier.find((e) => e.system === "https://profiletagline/" && e.value)?.value &&
                                    <div className="flx-center ">
                                        <img src={Stethoscope} alt="doctor" style={{ width: "25px", height: "25px" }} />
                                        <span className="fntMd" style={{ width: "100%", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>{state.practioner?.identifier.find((e) => e.system === "https://profiletagline/" && e.value)?.value?.replace(/,/g, ' , ')}</span>
                                    </div>
                                }
                                <div>
                                    <span className="fntMd fntSemiBold pt-05">HPR ID </span>: <span className="fntSm ">XXXXXXXXXXXXXXXXXXX</span>
                                </div>
                                <div className="fntXSm fntLight pt-05">Scan the QR Code to find this Doctor in other Apps & Hospital Website</div>
                            </div>
                        </div>
                        <div style={{ width: "30%", display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: "" }} >
                            <div style={{ width: "80px", height: "80px", padding: "5px" }} className="border_img">
                                <img src={QRCODE} style={{ height: "80px", width: "80px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileVerified;
